.sch_hola{
    width: 100vw;
    height: 100vh;
    background-color: rgb(249 250 251);
    position: fixed;
    z-index: 999;
    transition: transform 0.5s ease-out;


}

.sch_hola.closed {
    transform: translateY(-100%);


}
.sch_preloader {
	position:relative;
    width: 80px;
    height: 80px;
    top: 45%;
    margin: 0 auto;
}
.sch_preloader span {
	position:absolute;
	border: 8px solid #ff335b;
	border-top: 8px solid transparent;
	border-radius:999px;
}

.sch_preloader span:nth-child(1){
	width:80px;
	height:80px;
	animation: spin-1 2s infinite linear;
}
.sch_preloader span:nth-child(2){
	top: 20px;
	left: 20px;
	width:40px;
	height:40px;
	animation: spin-2 1s infinite linear;
}
@keyframes spin-1 {
	0% {transform: rotate(360deg); opacity: 1;}
	50% {transform: rotate(180deg); opacity: 0.5;}
	100% {transform: rotate(0deg); opacity: 1;}
}
@keyframes spin-2 {
	0% {transform: rotate(0deg); opacity: 0.5;}
	50% {transform: rotate(180deg); opacity: 1;}
	100% {transform: rotate(360deg); opacity: 0.5;}
}
